.cursor-pointer {
	cursor: pointer;
}
.login input::placeholder,
input.bg-dark::placeholder,
textarea.bg-dark::placeholder {
	color: #fff;
}
.slideRight {
	transition: all 0.5s;
	animation: 0.5s slideRight forwards;
}
.slideLeft {
	transition: all 0.5s;
	animation: 0.5s slideLeft forwards;
}
.fadeIn {
	animation: 0.5s fadeIn forwards;
}
.fadeOut {
	animation: 0.5s fadeOut forwards;
}
@keyframes slideRight {
	from {
		transform: translate(0, 0);
	}
	to {
		transform: translate(100%, 0);
	}
}
@keyframes slideLeft {
	from {
		transform: translate(0, 0);
	}
	to {
		transform: translate(-100%, 0);
	}
}
@keyframes fadeIn {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
@keyframes fadeOut {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
